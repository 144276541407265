import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import {themeBright} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import ThemeBox from "../../components/bricks/ThemeBox";

const Features = ({location}) => (
  <DefaultLayout
    title="Metrics & Reports"
    description="'Will we manage to release in time?' is a tough question. Especially in game development. Codecks is here to help."
    location={location}
  >
    <Hero size="sm" title="Metrics & Reports" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription img="metrics-1.png">
        Will we hit the next milestone? Is the workload evenly distributed? How reliable are our
        effort estimates? Our metrics are a good starting point to answer this kind of questions.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Drill down on the tasks that matter"
        img="metrics-2.png"
        imgRight
      >
        Metrics can be accessed for different levels within your project. Either for a single deck,
        a milestone or your whole project. You're even able to filter further using our search
        widget.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="What's the overall state of the project?"
        img="metrics-3.png"
      >
        The cardmap & workload metric gives you a top-down overview over the total load and who is
        bearing most of it. The color code indicates the status of each individual card.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription title="Burn those cards" img="metrics-4.png" imgRight>
        The burndown chart helps you see the buildup and burndown of your workload. Depending on
        your needs you can use your effort points or simply the card count as unit of work. The
        "target velocity" slider allows you to see how much work per week is necessary to meet the
        next milestone. Compare it with your past velocity to get an idea of how reasonable that
        estimate is!
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="How has the project developed?"
        img="metrics-5.png"
      >
        Curious about the overall development of your deck, milestone, or project? The cumulative
        flow diagram helps you get a feeling for your progress.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
